import { useEffect } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

/**
 * trigger 값이 변경될 때마다 setTimeout을 실행하는 hook
 * @author Dongseek Son
 * @param {function} onTime - 설정 시간 이후 실행할 function(해당 function에서 trigger의 설정한 값을 변경 시 타이머 재실행)
 * @param { number | {hour:string|number, minute:string|number, second:string|number}} time - 설정 시간
 * @param {any} trigger - 해당 값이 변경될 때마다 타이머 실행
 * @throws parseInt Error
 */
export const useTimer = (onTime, time, trigger) => {
  useEffect(() => {
    if(time) {
      let timer = setTimeout(onTime, typeof time === 'number' ? time : dayjs.duration(time).asMilliseconds());
      return () => {
        clearTimeout(timer);
      };
    }
  }, [onTime, time, trigger]);
};
