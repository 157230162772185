import { createSlice, createDraftSafeSelector, PayloadAction, } from '@reduxjs/toolkit';

interface AppInfoState {
  appId: string;
  appNm: string;
  appType: string;
}

interface AppState {
  appList: AppInfoState[];
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const appInitialState: AppState = {
  appList: [],
  actionResult: '',
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: 'app',
  initialState: appInitialState,
  reducers: {
    initAppState(state: AppState) {
      state.appList = [];
      state.actionResult = '';
      state.isLoading = false;
      state.error = null;
    },
    getAppList(state: AppState) {
      state.actionResult = 'APP_LIST_REQ';
      state.isLoading = true;
      state.error = null;
    },
    getAppListSuccess(state: AppState, { payload: appList }: PayloadAction<AppInfoState[]>) {
      state.appList = appList;
      state.actionResult = 'APP_LIST_OK';
      state.isLoading = false;
      state.error = null;
    },
    getAppListFailure(state: AppState, { payload: error }: PayloadAction<string>) {
      state.actionResult = 'APP_LIST_ERR';
      state.isLoading = false;
      state.error = error;
    },
  },
});

const selectAppList = createDraftSafeSelector(
  (state: AppState) => state.appList,
  (appList) => (appList),
)

const selectFilterAppList = createDraftSafeSelector(
  (state: any) => state,
  state => {
    const appList = state.app.appList;
    const { pricing } = state.place.placeInfo;
    const pricingLevel = { BASIC: 1, STANDARD: 2, ENTERPRISE: 3 };
    const pricingFilterAppList = appList.filter(app => pricingLevel[pricing] >= pricingLevel[app.pricing]);

    const URL = window.location.href;

    if (URL.indexOf('localhost') > 0 || URL.indexOf('eligaws') > 0) {
      return pricingFilterAppList;
    } else {
      return pricingFilterAppList.filter(app => app.devYn === 'N');
    }
  }
)

const selectStatus = createDraftSafeSelector(
  (state: AppState) => state.isLoading,
  (state: AppState) => state.error,
  (state: AppState) => state.actionResult,
  (isLoading, error, actionResult) => ({ isLoading, error, actionResult }),
);

export const appSelector = {
  appList: (state) => selectAppList(state[APP]),
  filterAppList: (state) => selectFilterAppList(state),
  status: (state) => selectStatus(state[APP]),
};

export const APP = slice.name;
export const appReducer = slice.reducer;
export const appAction = slice.actions;