import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface BoardPayload {
  placeId: string;
  accountId: string;
  accountNm: string;
  filterType: string;
  filterValue: string;
  rowsPerPage: number;
  curPage: number;
}

interface FilterInfo {
  filterType: string;
  filterValue: string;
}

interface PageInfo {
  rowsPerPage: number;
  curPage: number;
}

interface NoticeInfo {
  noticeId: string;
  noticeTitle: string;
  noticeDetail: string;
  regDt: string;
}

interface ReleaseInfo {
  releaseVersion: string;
  releaseTitle: string;
  releaseDetail: string;
  regDt: string;
}

interface QnaInfo {
  placeId: string;
  placeNm: string;
  qId: string;
  qTitle: string;
  qNm: string;
  regDt: string;
}

interface GuideInfo {
  guideId: string;
  guideTitle: string;
  regDt: string;
}

interface BoardState {
  filterInfo: FilterInfo;
  pageInfo: PageInfo;
  noticeList: Array<NoticeInfo>;
  noticeInfo: NoticeInfo;
  releaseList: Array<ReleaseInfo>;
  releaseInfo: ReleaseInfo;
  qnaList: Array<QnaInfo>;
  qnaInfo: QnaInfo;
  guideList: Array<GuideInfo>;
  guideInfo: GuideInfo;
  actionResult: string;
  isLoading: boolean;
  error: string;
}

const boardInitialState: BoardState = {
  filterInfo: {},
  pageInfo: { rowsPerPage: 10, curPage: 1 },
  noticeList: [],
  noticeInfo: {},
  releaseList: [],
  releaseInfo: {},
  qnaList: [],
  qnaInfo: {},
  guideList: [],
  guideInfo: {},
  actionResult: '',
  isLoading: false,
  error: '',
};

const reducers = {
  filter: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.filterInfo.filterType = payload.filterType;
    state.filterInfo.filterValue = payload.filterValue;
    state.pageInfo.curPage = 1;
  },
  page: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.pageInfo.rowsPerPage = payload.rowsPerPage;
    state.pageInfo.curPage = payload.curPage;
  },
  noticeList: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.actionResult = 'NOTICE_LIST_REQ';
    state.isLoading = true;
    state.error = '';
  },
  noticeListSuccess: (
    state: BoardState,
    { payload }: PayloadAction<BoardState>,
  ) => {
    state.pageInfo.curPage = 1;
    state.noticeList = payload.noticeList;
    state.noticeList.sort((a, b) =>
      a.regDt === b.regDt ? 0 : a.regDt > b.regDt ? -1 : 1,
    );
    state.actionResult = 'NOTICE_LIST_OK';
    state.isLoading = false;
    state.error = '';
  },
  noticeListFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'NOTICE_LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  noticeListClear: (state: BoardState) => {
    state.filterInfo = {};
    state.pageInfo = { rowsPerPage: 10, curPage: 1 };
    state.noticeList = [];
  },
  noticeDetail: (
    state: BoardState,
    { payload }: PayloadAction<BoardPayload>,
  ) => {
    state.actionResult = 'NOTICE_DETAIL_REQ';
    state.isLoading = true;
    state.error = '';
  },
  noticeDetailSuccess: (
    state: BoardState,
    { payload }: PayloadAction<BoardState>,
  ) => {
    state.noticeInfo = payload.noticeInfo;
    state.actionResult = 'NOTICE_DETAIL_OK';
    state.isLoading = false;
    state.error = '';
  },
  noticeDetailFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'NOTICE_DETAIL_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  noticeDetailClear: (state: BoardState) => {
    state.noticeInfo = {};
  },
  releaseList: (
    state: BoardState,
    { payload }: PayloadAction<BoardPayload>,
  ) => {
    state.actionResult = 'RELEASE_LIST_REQ';
    state.isLoading = true;
    state.error = '';
  },
  releaseListSuccess: (
    state: BoardState,
    { payload }: PayloadAction<BoardState>,
  ) => {
    state.pageInfo.curPage = 1;
    state.releaseList = payload.releaseList;
    state.releaseList.sort((a, b) =>
      a.regDt === b.regDt ? 0 : a.regDt > b.regDt ? -1 : 1,
    );
    state.actionResult = 'RELEASE_LIST_OK';
    state.isLoading = false;
    state.error = '';
  },
  releaseListFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'RELEASE_LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  releaseListClear: (state: BoardState) => {
    state.filterInfo = {};
    state.pageInfo = { rowsPerPage: 10, curPage: 1 };
    state.releaseList = [];
  },
  releaseDetail: (
    state: BoardState,
    { payload }: PayloadAction<BoardPayload>,
  ) => {
    state.actionResult = 'RELEASE_DETAIL_REQ';
    state.isLoading = true;
    state.error = '';
  },
  releaseDetailSuccess: (
    state: BoardState,
    { payload }: PayloadAction<BoardState>,
  ) => {
    state.releaseInfo = payload.releaseInfo;
    state.actionResult = 'RELEASE_DETAIL_OK';
    state.isLoading = false;
    state.error = '';
  },
  releaseDetailFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'RELEASE_DETAIL_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  releaseDetailClear: (state: BoardState) => {
    state.releaseInfo = {};
  },
  qnaList: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.actionResult = 'QNA_LIST_REQ';
    state.isLoading = true;
    state.error = '';
  },
  qnaListSuccess: (
    state: BoardState,
    { payload }: PayloadAction<BoardState>,
  ) => {
    state.pageInfo.curPage = 1;
    state.qnaList = payload.qnaList;
    state.qnaList.sort((a, b) =>
      a.qDt === b.qDt ? 0 : a.qDt > b.qDt ? -1 : 1,
    );
    state.actionResult = 'QNA_LIST_OK';
    state.isLoading = false;
    state.error = '';
  },
  qnaListFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'QNA_LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  qnaListClear: (state: BoardState) => {
    state.filterInfo = {};
    state.pageInfo = { rowsPerPage: 10, curPage: 1 };
    state.qnaList = [];
  },
  qnaDetail: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.actionResult = 'QNA_DETAIL_REQ';
    state.isLoading = true;
    state.error = '';
  },
  qnaDetailSuccess: (
    state: BoardState,
    { payload }: PayloadAction<BoardState>,
  ) => {
    state.qnaInfo = payload.qnaInfo;
    state.actionResult = 'QNA_DETAIL_OK';
    state.isLoading = false;
    state.error = '';
  },
  qnaDetailFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'QNA_DETAIL_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  qnaDetailClear: (state: BoardState) => {
    state.qnaInfo = {};
  },
  qnaAdd: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.actionResult = 'QNA_ADD_REQ';
    state.isLoading = true;
    state.error = '';
  },
  qnaAddSuccess: (state: BoardState) => {
    state.actionResult = 'QNA_ADD_OK';
    state.isLoading = false;
    state.error = '';
  },
  qnaAddFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'QNA_ADD_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  qnaEdit: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.actionResult = 'QNA_EDIT_REQ';
    state.isLoading = true;
    state.error = '';
  },
  qnaEditSuccess: (state: BoardState) => {
    state.actionResult = 'QNA_EDIT_OK';
    state.isLoading = false;
    state.error = '';
  },
  qnaEditFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'QNA_EDIT_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  qnaCancel: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.actionResult = 'QNA_CANCEL_REQ';
    state.isLoading = true;
    state.error = '';
  },
  qnaCancelSuccess: (state: BoardState) => {
    state.actionResult = 'QNA_CANCEL_OK';
    state.qnaInfo.qnaStCd = 'CANCEL';
    state.isLoading = false;
    state.error = '';
  },
  qnaCancelFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'QNA_CANCEL_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  qnaRemove: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.actionResult = 'QNA_REMOVE_REQ';
    state.isLoading = true;
    state.error = '';
  },
  qnaRemoveSuccess: (state: BoardState) => {
    state.actionResult = 'QNA_REMOVE_OK';
    state.isLoading = false;
    state.error = '';
  },
  qnaRemoveFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'QNA_REMOVE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  guideList: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.actionResult = 'GUIDE_LIST_REQ';
    state.isLoading = true;
    state.error = '';
  },
  guideListSuccess: (
    state: BoardState,
    { payload }: PayloadAction<BoardState>,
  ) => {
    for (const guide of payload.guideList) {
      let newGuideFileList = [];
      for (const guideFile of payload.guideAllFileList) {
        if (guide.guideId === guideFile.guideId) {
          newGuideFileList.push(guideFile);
        }
      }
      guide.guideFileList = newGuideFileList;
    }

    state.pageInfo.curPage = 1;
    state.guideList = payload.guideList;
    state.guideList.sort((a, b) =>
      a.regDt === b.regDt ? 0 : a.regDt > b.regDt ? -1 : 1,
    );
    state.actionResult = 'GUIDE_LIST_OK';
    state.isLoading = false;
    state.error = '';
  },
  guideListFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'GUIDE_LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  guideListClear: (state: BoardState) => {
    state.filterInfo = {};
    state.pageInfo = { rowsPerPage: 10, curPage: 1 };
    state.guideList = [];
  },
  guideDetail: (
    state: BoardState,
    { payload }: PayloadAction<BoardPayload>,
  ) => {
    state.actionResult = 'GUIDE_DETAIL_REQ';
    state.isLoading = true;
    state.error = '';
  },
  guideDetailSuccess: (
    state: BoardState,
    { payload : { guideInfo, guideFileList } }: PayloadAction<BoardState>,
  ) => {
    state.guideInfo = {
      ...guideInfo,
      guideFileList
    };
    state.actionResult = 'GUIDE_DETAIL_OK';
    state.isLoading = false;
    state.error = '';
  },
  guideFileDownloadRequest: (
    state : BoardState,
    { payload } : PayloadAction<GuideInfo>,
  ) => {
    state.actionResult = 'DOWNLOAD_REQ';
    state.isLoading = true;
    state.error = null;
  },
  guideFileDownloadSuccess: (state : AccountState) => {
    state.actionResult = 'GUIDE_FILE_DOWNLOAD_OK';
    state.isLoading = false;
    state.error = '';
  },
  guideFileDownloadFailure: (state : AccountState, action : PayloadAction<string>) => {
    state.actionResult = 'GUIDE_FILE_DOWNLOAD_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  guideFileListDownloadRequest: (
    state : BoardState,
    { payload } : PayloadAction<GuideInfo>,
  ) => {
    state.actionResult = 'DOWNLOAD_REQ';
    state.isLoading = true;
    state.error = null;
  },
  guideFileListDownloadSuccess: (state : AccountState) => {
    state.actionResult = 'GUIDE_FILE_LIST_DOWNLOAD_OK';
    state.isLoading = false;
    state.error = '';
  },
  guideFileListDownloadFailure: (state : AccountState, action : PayloadAction<string>) => {
    state.actionResult = 'GUIDE_FILE_LIST_DOWNLOAD_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  guideDetailFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'GUIDE_DETAIL_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  guideDetailClear: (state: BoardState) => {
    state.guideInfo = {};
  },
  actionResultClear: (state: BoardState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'board',
  initialState: boardInitialState,
  reducers: reducers,
});

const selectFilterInfo = createDraftSafeSelector(
  (state: BoardState) => state.filterInfo,
  filterInfo => filterInfo,
);

const selectPageInfo = createDraftSafeSelector(
  (state: BoardState) => state.pageInfo,
  pageInfo => {
    return {
      rowsPerPage: pageInfo.rowsPerPage,
      curPage: pageInfo.curPage,
    };
  },
);

const selectNoticeList = createDraftSafeSelector(
  (state: BoardState) => state.noticeList,
  noticeList => noticeList,
);

const selectNoticeFilterList = createDraftSafeSelector(
  (state: BoardState) => state.filterInfo,
  (state: BoardState) => state.pageInfo,
  (state: BoardState) => state.noticeList,
  (filterInfo, pageInfo, noticeList) => {
    if (filterInfo.filterType && filterInfo.filterType === 'category') {
      const filterNoticeList = noticeList.filter(
        notice => notice.noticeType === filterInfo.filterValue,
      );

      const totalCount = filterNoticeList.length;
      const startIndex = pageInfo.rowsPerPage * (pageInfo.curPage - 1);
      const endIndex = totalCount - startIndex;
      const totalPages =
        filterNoticeList.length % pageInfo.rowsPerPage > 0
          ? Math.floor(filterNoticeList.length / pageInfo.rowsPerPage) + 1
          : Math.floor(filterNoticeList.length / pageInfo.rowsPerPage);

      const sliceList = filterNoticeList.slice(
        pageInfo.rowsPerPage * (pageInfo.curPage - 1),
        pageInfo.rowsPerPage * pageInfo.curPage,
      );

      return {
        totalCount,
        startIndex,
        endIndex,
        totalPages,
        noticeList: sliceList,
      };
    }

    const totalCount = noticeList.length;
    const startIndex = pageInfo.rowsPerPage * (pageInfo.curPage - 1);
    const endIndex = totalCount - startIndex;
    const totalPages =
      noticeList.length % pageInfo.rowsPerPage > 0
        ? Math.floor(noticeList.length / pageInfo.rowsPerPage) + 1
        : Math.floor(noticeList.length / pageInfo.rowsPerPage);

    const sliceList = noticeList.slice(
      pageInfo.rowsPerPage * (pageInfo.curPage - 1),
      pageInfo.rowsPerPage * pageInfo.curPage,
    );

    return {
      totalCount,
      startIndex,
      endIndex,
      totalPages,
      noticeList: sliceList,
    };
  },
);

const selectNoticeInfo = createDraftSafeSelector(
  (state: BoardState) => state.noticeInfo,
  noticeInfo => noticeInfo,
);

const selectReleaseList = createDraftSafeSelector(
  (state: BoardState) => state.releaseList,
  releaseList => releaseList,
);

const selectReleaseFilterList = createDraftSafeSelector(
  (state: BoardState) => state.filterInfo,
  (state: BoardState) => state.pageInfo,
  (state: BoardState) => state.releaseList,
  (filterInfo, pageInfo, releaseList) => {
    if (filterInfo.filterType && filterInfo.filterType === 'category') {
      const filterReleaseList = releaseList.filter(
        release => release.releaseType === filterInfo.filterValue,
      );

      const totalCount = filterReleaseList.length;
      const startIndex = pageInfo.rowsPerPage * (pageInfo.curPage - 1);
      const endIndex = totalCount - startIndex;
      const totalPages =
        filterReleaseList.length % pageInfo.rowsPerPage > 0
          ? Math.floor(filterReleaseList.length / pageInfo.rowsPerPage) + 1
          : Math.floor(filterReleaseList.length / pageInfo.rowsPerPage);

      const sliceList = filterReleaseList.slice(
        pageInfo.rowsPerPage * (pageInfo.curPage - 1),
        pageInfo.rowsPerPage * pageInfo.curPage,
      );

      return {
        totalCount,
        startIndex,
        endIndex,
        totalPages,
        releaseList: sliceList,
      };
    }

    const totalCount = releaseList.length;
    const startIndex = pageInfo.rowsPerPage * (pageInfo.curPage - 1);
    const endIndex = totalCount - startIndex;
    const totalPages =
      releaseList.length % pageInfo.rowsPerPage > 0
        ? Math.floor(releaseList.length / pageInfo.rowsPerPage) + 1
        : Math.floor(releaseList.length / pageInfo.rowsPerPage);

    const sliceList = releaseList.slice(
      pageInfo.rowsPerPage * (pageInfo.curPage - 1),
      pageInfo.rowsPerPage * pageInfo.curPage,
    );

    return {
      totalCount,
      startIndex,
      endIndex,
      totalPages,
      releaseList: sliceList,
    };
  },
);

const selectReleaseInfo = createDraftSafeSelector(
  (state: BoardState) => state.releaseInfo,
  releaseInfo => releaseInfo,
);

const selectQnaList = createDraftSafeSelector(
  (state: BoardState) => state.qnaList,
  qnaList => qnaList,
);

const selectQnaFilterList = createDraftSafeSelector(
  (state: BoardState) => state.filterInfo,
  (state: BoardState) => state.pageInfo,
  (state: BoardState) => state.qnaList,
  (filterInfo, pageInfo, qnaList) => {
    if (filterInfo.filterType && filterInfo.filterType === 'category') {
      const filterQnaList = qnaList.filter(
        qna => qna.qnaStCd === filterInfo.filterValue,
      );

      const totalCount = filterQnaList.length;
      const startIndex = pageInfo.rowsPerPage * (pageInfo.curPage - 1);
      const endIndex = totalCount - startIndex;
      const totalPages =
        filterQnaList.length % pageInfo.rowsPerPage > 0
          ? Math.floor(filterQnaList.length / pageInfo.rowsPerPage) + 1
          : Math.floor(filterQnaList.length / pageInfo.rowsPerPage);

      const sliceList = filterQnaList.slice(
        pageInfo.rowsPerPage * (pageInfo.curPage - 1),
        pageInfo.rowsPerPage * pageInfo.curPage,
      );

      return {
        totalCount,
        startIndex,
        endIndex,
        totalPages,
        qnaList: sliceList,
      };
    }

    const totalCount = qnaList.length;
    const startIndex = pageInfo.rowsPerPage * (pageInfo.curPage - 1);
    const endIndex = totalCount - startIndex;
    const totalPages =
      qnaList.length % pageInfo.rowsPerPage > 0
        ? Math.floor(qnaList.length / pageInfo.rowsPerPage) + 1
        : Math.floor(qnaList.length / pageInfo.rowsPerPage);

    const sliceList = qnaList.slice(
      pageInfo.rowsPerPage * (pageInfo.curPage - 1),
      pageInfo.rowsPerPage * pageInfo.curPage,
    );

    return {
      totalCount,
      startIndex,
      endIndex,
      totalPages,
      qnaList: sliceList,
    };
  },
);

const selectQnaInfo = createDraftSafeSelector(
  (state: BoardState) => state.qnaInfo,
  qnaInfo => qnaInfo,
);

const selectGuideList = createDraftSafeSelector(
  (state: BoardState) => state.guideList,
  guideList => guideList,
);

const selectGuideFilterList = createDraftSafeSelector(
  (state: BoardState) => state.filterInfo,
  (state: BoardState) => state.pageInfo,
  (state: BoardState) => state.guideList,
  (state: BoardState) => state.guideInfo,
  (filterInfo, pageInfo, guideList, guideInfo) => {
    if (filterInfo.filterType && filterInfo.filterType === 'category') {
      const filterGuideList = guideList.filter(
        guide => guide.guideType === filterInfo.filterValue,
      );

      const totalCount = filterGuideList.length;
      const startIndex = pageInfo.rowsPerPage * (pageInfo.curPage - 1);
      const endIndex = totalCount - startIndex;
      const totalPages =
        filterGuideList.length % pageInfo.rowsPerPage > 0
          ? Math.floor(filterGuideList.length / pageInfo.rowsPerPage) + 1
          : Math.floor(filterGuideList.length / pageInfo.rowsPerPage);

      const sliceList = filterGuideList.slice(
        pageInfo.rowsPerPage * (pageInfo.curPage - 1),
        pageInfo.rowsPerPage * pageInfo.curPage,
      );

      return {
        totalCount,
        startIndex,
        endIndex,
        totalPages,
        guideList: sliceList,
      };
    }

    const totalCount = guideList.length;
    const startIndex = pageInfo.rowsPerPage * (pageInfo.curPage - 1);
    const endIndex = totalCount - startIndex;
    const totalPages =
      guideList.length % pageInfo.rowsPerPage > 0
        ? Math.floor(guideList.length / pageInfo.rowsPerPage) + 1
        : Math.floor(guideList.length / pageInfo.rowsPerPage);

    const sliceList = guideList.slice(
      pageInfo.rowsPerPage * (pageInfo.curPage - 1),
      pageInfo.rowsPerPage * pageInfo.curPage,
    );

    return {
      totalCount,
      startIndex,
      endIndex,
      totalPages,
      guideList: sliceList,
    };
  },
);

const selectGuideInfo = createDraftSafeSelector(
  (state: BoardState) => state.guideInfo,
  guideInfo => guideInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: BoardState) => state.actionResult,
  (state: BoardState) => state.isLoading,
  (state: BoardState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const boardSelector = {
  filterInfo: state => selectFilterInfo(state[BOARD]),
  pageInfo: state => selectPageInfo(state[BOARD]),
  noticeList: state => selectNoticeList(state[BOARD]),
  noticeFilterList: state => selectNoticeFilterList(state[BOARD]),
  noticeInfo: state => selectNoticeInfo(state[BOARD]),
  releaseList: state => selectReleaseList(state[BOARD]),
  releaseFilterList: state => selectReleaseFilterList(state[BOARD]),
  releaseInfo: state => selectReleaseInfo(state[BOARD]),
  qnaList: state => selectQnaList(state[BOARD]),
  qnaFilterList: state => selectQnaFilterList(state[BOARD]),
  qnaInfo: state => selectQnaInfo(state[BOARD]),
  guideList: state => selectGuideList(state[BOARD]),
  guideFilterList: state => selectGuideFilterList(state[BOARD]),
  guideInfo: state => selectGuideInfo(state[BOARD]),
  status: state => selectStatus(state[BOARD]),
};

export const BOARD = slice.name;
export const boardReducer = slice.reducer;
export const boardAction = slice.actions;
