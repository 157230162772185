import React, { useState, useEffect, useCallback, Suspense } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import FadeLoader from 'react-spinners/FadeLoader';
import { useSelector } from 'react-redux';

import { authSelector } from '../../module/authSlice';

const Token = React.lazy(() => import('../auth/Token'));
const Header = React.lazy(() => import('../common/Header'));
const NavBar = React.lazy(() => import('../common/NavBar'));
const Footer = React.lazy(() => import('../common/Footer'));

const loading = () => (
  <div
    style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <FadeLoader color={'#41a1ea'} loading={true} size={20} />
  </div>
);

const contentLoading = () => (
  <div
    style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <FadeLoader color={'#41a1ea'} loading={true} size={20} />
  </div>
);

const Manager = () => {
  const location = useLocation();
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const status = useSelector(authSelector.status);

  useEffect(() => {
    const pathname = location.pathname;

    if (pathname === '/') {
      document.title = 'ELIGA';
    } else if (pathname.indexOf('/dashboard') === 0) {
      document.title = 'ELIGA - 대시보드';
    } else if (pathname.indexOf('/playlist') === 0) {
      document.title = 'ELIGA - 재생목록';
    } else if (pathname.indexOf('/device') === 0) {
      document.title = 'ELIGA - 디바이스';
    } else if (pathname.indexOf('/storage') === 0) {
      document.title = 'ELIGA - 파일 보관함';
    } else if (pathname.indexOf('/report') === 0) {
      document.title = 'ELIGA - 리포트';
    } else if (pathname.indexOf('/system/setting') === 0) {
      document.title = 'ELIGA - 플레이스 설정';
    } else if (pathname.indexOf('/system/member') === 0) {
      document.title = 'ELIGA - 멤버 설정';
    } else if (pathname.indexOf('/profile') === 0) {
      document.title = 'ELIGA - 내 계정';
    } else if (pathname.indexOf('/notice') === 0) {
      document.title = 'ELIGA - 공지사항';
    } else if (pathname.indexOf('/release') === 0) {
      document.title = 'ELIGA - 업데이트 안내';
    } else if (pathname.indexOf('/qna') === 0) {
      document.title = 'ELIGA - 온라인 문의';
    } else if (pathname.indexOf('/guide') === 0) {
      document.title = 'ELIGA - 사용자 가이드';
    } else if (pathname.indexOf('/channel') === 0) {
      document.title = 'ELIGA - 채널';
    } else {
      document.title = 'ELIGA';
    }
  }, [location]);

  const onMenuToggle = useCallback(() => {
    setIsMenuOpen(!isMenuOpen);
  }, [isMenuOpen]);

  if (!status.isLogin) {
    return (
      <Suspense fallback={loading()}>
        <Token />
      </Suspense>
    );
  }

  return (
    <Container $isMobile={isMobile}>
      {isMenuOpen && (
        <Suspense fallback={loading()}>
          <NavBar />
        </Suspense>
      )}
      <Content $isMobile={isMobile} $isMenuOpen={isMenuOpen}>
        <Suspense fallback={loading()}>
          <Header onMenuToggle={onMenuToggle} />
        </Suspense>
        <Suspense fallback={contentLoading()}>
          <Outlet />
        </Suspense>
        <Suspense fallback={loading()}>
          <Footer />
        </Suspense>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: ${props => (props.$isMobile ? 'column' : 'row')};
`;

const Content = styled.div`
  flex: 1;
  margin-top: ${props => (props.$isMobile ? '4rem' : '0')};
  margin-left: ${props => (props.$isMobile || !props.$isMenuOpen ? '0' : '12.5rem')};
  display: flex;
  flex-direction: column;
`;

export default Manager;
