import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { SketchPicker } from 'react-color';

import Loading from '../../../newComponents/Loading';
import useForm from './components/useForm';
import AppTitle from './components/AppTitle';
import ContentBox, { AppContentWrap } from './components/ContentBox';
import AppButtonArea from './components/AppButtonArea';

import { INIT_RSS_INFO } from '../constants/contentApp';

import SelectInput from '../../../newComponents/SelectInput';
import { ReactComponent as FontColorIcon } from '../../../assets/images/fontOption/icon-font-color.svg';
import { ReactComponent as BoldIcon } from '../../../assets/images/fontOption/icon-font-option-bold.svg';
import { ReactComponent as ItalicIcon } from '../../../assets/images/fontOption/icon-font-option-italic.svg';
import { ReactComponent as LineThroughIcon } from '../../../assets/images/fontOption/icon-font-option-line-through.svg';
import { ReactComponent as ShadowIcon } from '../../../assets/images/fontOption/icon-font-option-shadow.svg';
import { ReactComponent as UnderlineIcon } from '../../../assets/images/fontOption/icon-font-option-underline.svg';
import ClickOutside from '../../../components/ClickOutside';

const RssViewApp = ({ selectLayerContentsInfo = INIT_RSS_INFO, handleCancelLayerContents, selectedLayerType, appLoading }) => {
  const [layerContentsInfo, setLayerContentsInfo] = useState(INIT_RSS_INFO);

  const { formData, handleChangeObject, handleChange } = useForm({ initialValues: layerContentsInfo });

  const [isRssOpen, setIsRssOpen] = useState(true);
  const [isOptionOpen, setIsOptionOpen] = useState(true);
  const [isFontOpen, setIsFontOpen] = useState(true);

  const [isFontColorOpen, setIsFontColorOpen] = useState(false);

  const rssTitleOptionList = useRef([
    { value: 'mbn', title: 'MBN' },
    // { value: 'yonhapnews', title: '연합뉴스' },
    // { value: 'chosun', title: '조선일보' },
  ]);

  const rssSubTitleOptionList = useRef({
    mbn: [
      { value: 'mbn-all', title: '전체' },
      { value: 'mbn-politics', title: '정치' },
      { value: 'mbn-economy', title: '경제' },
    ],
    yonhapnews: [
      { value: 'yonhapnewstv-all', title: '최신' },
      { value: 'yonhapnewstv-headline', title: '헤드라인' },
      { value: 'yonhapnewstv-politics', title: '정치' },
      { value: 'yonhapnewstv-economy', title: '경제' },
    ],
    chosun: [
      { value: 'chosun-headline', title: '주요뉴스' },
      { value: 'chosun-hit', title: '인기뉴스' },
    ],
  });

  const InitFontSizeOptionList = useRef([
    { value: '8', title: '8' },
    { value: '9', title: '9' },
    { value: '10', title: '10' },
    { value: '11', title: '11' },
    { value: '12', title: '12' },
    { value: '14', title: '14' },
    { value: '16', title: '16' },
    { value: '18', title: '18' },
    { value: '19', title: '19' },
    { value: '20', title: '20' },
    { value: '24', title: '24' },
    { value: '28', title: '28' },
    { value: '32', title: '32' },
    { value: '36', title: '36' },
    { value: '40', title: '40' },
    { value: '44', title: '44' },
    { value: '48', title: '48' },
    { value: '50', title: '50' },
    { value: '54', title: '54' },
    { value: '60', title: '60' },
    { value: '70', title: '70' },
    { value: '80', title: '80' },
    { value: '90', title: '90' },
  ]);
  const transitionTimeOptionList = useRef([
    { value: '3', title: '3' },
    { value: '5', title: '5' },
    { value: '7', title: '7' },
    { value: '10', title: '10' },
  ]);
  const animationOptionList = useRef([
    { value: 'bottomToTop', title: '위로 올리기' },
    { value: 'topToBottom', title: '아래로 내리기' },
    { value: 'rightToLeft', title: '옆으로 넘기기' },
  ]);

  useEffect(() => {
    if (selectLayerContentsInfo) {
      let layerContentsInfo = { ...selectLayerContentsInfo };
      if (!layerContentsInfo.contentsData.rssId) {
        layerContentsInfo.contentsData.rssId = formData.contentsData.rssSubTitle;
      }
      if (!layerContentsInfo.contentsData.rssTitle) {
        layerContentsInfo.contentsData.rssTitle = formData.contentsData.rssTitle;
      }
      if (!layerContentsInfo.contentsData.rssSubTitle) {
        layerContentsInfo.contentsData.rssSubTitle = formData.contentsData.rssSubTitle;
      }
      if (!layerContentsInfo.contentsData.color) {
        layerContentsInfo.contentsData.color = { r: '102', g: '102', b: '102', a: '100' };
      }
      if (!layerContentsInfo.contentsData.fontSize) {
        layerContentsInfo.contentsData.fontSize = '10';
      }
      if (!layerContentsInfo.contentsData.boldYn) {
        layerContentsInfo.contentsData.boldYn = 'N';
      }
      if (!layerContentsInfo.contentsData.italicYn) {
        layerContentsInfo.contentsData.italicYn = 'Y';
      }
      if (!layerContentsInfo.contentsData.underlineYn) {
        layerContentsInfo.contentsData.underlineYn = 'N';
      }
      if (!layerContentsInfo.contentsData.lineThroughYn) {
        layerContentsInfo.contentsData.lineThroughYn = 'N';
      }
      if (!layerContentsInfo.contentsData.textShadowYn) {
        layerContentsInfo.contentsData.textShadowYn = 'N';
      }
      if (!layerContentsInfo.contentsData.transitionTime) {
        layerContentsInfo.contentsData.transitionTime = '3';
      }
      if (!layerContentsInfo.contentsData.animation) {
        layerContentsInfo.contentsData.animation = 'bottomToTop';
      }
      setLayerContentsInfo(layerContentsInfo);
    } else {
      setLayerContentsInfo(INIT_RSS_INFO);
    }
  }, [formData.contentsData, formData.contentsData.rssSubTitle, formData.contentsData.rssTitle, selectLayerContentsInfo]);

  return (
    <Container>
      <AppTitle text="RSS App" />
      <AppContentWrap>
        {appLoading ? (
          <Loading />
        ) : (
          <>
            <ContentBox title="RSS 설정" isOpen={isRssOpen} changeOpen={setIsRssOpen} line>
              <Box>
                <LeftArea>
                  <Title>구독</Title>
                </LeftArea>
                <RightArea>
                  <SelectInput
                    selectedValue={formData.contentsData.rssTitle}
                    optionList={rssTitleOptionList.current}
                    onSelectChange={value => {
                      let rssSubTitle = formData.contentsData.rssTitle === value ? formData.contentsData.rssSubTitle : rssSubTitleOptionList.current[value][0].value;
                      handleChange('contentsData', { ...formData.contentsData, rssTitle: value, rssSubTitle: rssSubTitle, rssId: rssSubTitle });
                    }}
                  />
                </RightArea>
              </Box>
              <Box>
                <LeftArea>
                  <Title>분류</Title>
                </LeftArea>
                <RightArea>
                  <SelectInput
                    selectedValue={formData.contentsData.rssSubTitle}
                    optionList={rssSubTitleOptionList.current[formData.contentsData.rssTitle]}
                    onSelectChange={value => handleChangeObject('contentsData', 'rssSubTitle', value)}
                  />
                </RightArea>
              </Box>
            </ContentBox>
            <ContentBox title="전환 설정" isOpen={isOptionOpen} changeOpen={setIsOptionOpen} line>
              <Box>
                <LeftArea>
                  <Title>간격 (초)</Title>
                </LeftArea>
                <RightArea>
                  <SelectInput
                    selectedValue={formData.contentsData.transitionTime}
                    optionList={transitionTimeOptionList.current}
                    onSelectChange={value => handleChangeObject('contentsData', 'transitionTime', value)}
                  />
                </RightArea>
              </Box>
              <Box>
                <LeftArea>
                  <Title>효과 (방향)</Title>
                </LeftArea>
                <RightArea>
                  <SelectInput
                    selectedValue={formData.contentsData.animation}
                    optionList={animationOptionList.current}
                    onSelectChange={value => handleChangeObject('contentsData', 'animation', value)}
                  />
                </RightArea>
              </Box>
            </ContentBox>
            <ContentBox title="폰트 옵션" isOpen={isFontOpen} changeOpen={setIsFontOpen}>
              <FontWrapper>
                <FontColorWrap>
                  <FontBtn fontColor={formData.contentsData.color} onClick={() => setIsFontColorOpen(!isFontColorOpen)}>
                    <FontColorIcon />
                  </FontBtn>
                  <ClickOutside onClickOutside={() => setIsFontColorOpen(false)}>
                    <FontColorModal style={isFontColorOpen ? { display: 'block', zIndex: '99' } : { display: 'none' }}>
                      <SketchPicker color={formData.contentsData.color} onChange={color => handleChangeObject('contentsData', 'color', color.rgb)} />
                    </FontColorModal>
                  </ClickOutside>
                </FontColorWrap>
                <FontBtn
                  className={formData.contentsData.boldYn === 'Y' && 'active'}
                  onClick={() => handleChangeObject('contentsData', 'boldYn', formData.contentsData.boldYn === 'N' ? 'Y' : 'N')}
                >
                  <BoldIcon />
                </FontBtn>
                <FontBtn
                  className={formData.contentsData.italicYn === 'Y' && 'active'}
                  onClick={() => handleChangeObject('contentsData', 'italicYn', formData.contentsData.italicYn === 'N' ? 'Y' : 'N')}
                >
                  <ItalicIcon />
                </FontBtn>
                <FontBtn
                  className={formData.contentsData.underlineYn === 'Y' && 'active'}
                  onClick={() => handleChangeObject('contentsData', 'underlineYn', formData.contentsData.underlineYn === 'N' ? 'Y' : 'N')}
                >
                  <UnderlineIcon />
                </FontBtn>
                <FontBtn
                  className={formData.contentsData.textShadowYn === 'Y' && 'active'}
                  onClick={() => handleChangeObject('contentsData', 'textShadowYn', formData.contentsData.textShadowYn === 'N' ? 'Y' : 'N')}
                >
                  <ShadowIcon />
                </FontBtn>
                <FontBtn
                  className={formData.contentsData.lineThroughYn === 'Y' && 'active'}
                  onClick={() => handleChangeObject('contentsData', 'lineThroughYn', formData.contentsData.lineThroughYn === 'N' ? 'Y' : 'N')}
                >
                  <LineThroughIcon />
                </FontBtn>
                <CustomSelectInput
                  selectedValue={formData.contentsData.fontSize}
                  optionList={InitFontSizeOptionList.current}
                  onSelectChange={value => handleChangeObject('contentsData', 'fontSize', value)}
                />
              </FontWrapper>
            </ContentBox>
          </>
        )}
      </AppContentWrap>
      <AppButtonArea formData={formData} cancelApp={handleCancelLayerContents} selectedLayerType={selectedLayerType} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  & + & {
    margin-top: 10px;
  }
`;

const LeftArea = styled.div`
  flex: 1;
  align-items: center;
`;
const RightArea = styled.div`
  flex: 3;
  align-items: center;
`;
const Title = styled.div`
  width: 100%;
  font-size: 14px;
`;
const FontWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FontBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid #dddddd;
  border-radius: 6px;
  background: none;
  margin-right: 10px;
  margin-bottom: 10px;

  &.active {
    color: #2a91df;
    background-color: #e3f0fa;
  }

  &:hover {
    background-color: #efeff4;
  }

  &:disabled {
    background-color: #efeff4;
  }

  svg {
    & .font-color {
      fill: ${({ fontColor }) => fontColor && `rgb(${fontColor.r}, ${fontColor.g}, ${fontColor.b})`};
      stroke: ${({ fontColor }) => fontColor && `rgb(${fontColor.r}, ${fontColor.g}, ${fontColor.b})`};
    }
  }
`;

const FontColorWrap = styled.div`
  position: relative;
`;

const FontColorModal = styled.div`
  position: absolute;
`;
const CustomSelectInput = styled(SelectInput)`
  width: 60px;
`;
export default React.memo(RssViewApp);
