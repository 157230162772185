import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface AccountPayload {
  placeId: string;
  accountId: string;
  accountNm: string;
}

interface ImagePayload {
  accountId: string;
  file: Array<any>;
}

interface AccountInfo {
  placeId: string;
  viewPlaceId: string;
  accountId: string;
  accountNm: string;
  accountEmail: string;
  imagePath: string;
  imageSize: string;
  regDt: string;
}

interface MemberInfo {
  placeId: string;
  accountId: string;
  accountNm: string;
  permissionType: string;
  permissionNm: string;
}

interface AccountState {
  accountInfo: AccountInfo;
  memberInfo: MemberInfo;
  actionResult: string;
  isLoading: boolean;
  error: string;
}

const accountInitialState: AccountState = {
  accountInfo: {},
  memberInfo: {},
  actionResult: '',
  isLoading: false,
  error: '',
};

const reducers = {
  detail: (state: AccountState, { payload }: PayloadAction<AccountPayload>) => {
    state.actionResult = 'DETAIL_REQ';
    state.isLoading = true;
    state.error = '';
  },
  detailSuccess: (
    state: AccountState,
    { payload }: PayloadAction<AccountState>,
  ) => {
    state.accountInfo = payload.accountInfo;
    state.memberInfo = payload.memberInfo;
    state.actionResult = 'DETAIL_OK';
    state.isLoading = false;
    state.error = '';
  },
  detailFailure: (state: AccountState, action: PayloadAction<string>) => {
    state.actionResult = 'DETAIL_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  edit: (state: AccountState, { payload }: PayloadAction<AccountPayload>) => {
    state.actionResult = 'EDIT_REQ';
    state.isLoading = true;
    state.error = '';
  },
  editSuccess: (state: AccountState, { payload }: PayloadAction<AccountPayload>) => {
    state.accountInfo.accountNm = payload.accountNm;
    state.actionResult = 'EDIT_OK';
    state.isLoading = false;
    state.error = '';
  },
  editFailure: (state: AccountState, action: PayloadAction<string>) => {
    state.actionResult = 'EDIT_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  remove: (state: AccountState, { payload }: PayloadAction<AccountPayload>) => {
    state.actionResult = 'REMOVE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  removeSuccess: (state: AccountState) => {
    state.actionResult = 'REMOVE_OK';
    state.isLoading = false;
    state.error = null;
  },
  removeFailure: (state: AccountState, action: PayloadAction<string>) => {
    state.actionResult = 'REMOVE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  passwordChange: (
    state: AccountState,
    { payload }: PayloadAction<AccountPayload>,
  ) => {
    state.actionResult = 'PASSWORD_CHANGE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  passwordChangeSuccess: (state: AccountState) => {
    state.actionResult = 'PASSWORD_CHANGE_OK';
    state.isLoading = false;
    state.error = null;
  },
  passwordChangeFailure: (
    state: AccountState,
    action: PayloadAction<string>,
  ) => {
    state.actionResult = 'PASSWORD_CHANGE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  placeChange: (
    state: AccountState,
    { payload }: PayloadAction<AccountPayload>,
  ) => {
    state.actionResult = 'PLACE_CHANGE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  placeChangeSuccess: (
    state: AccountState,
    { payload }: PayloadAction<AccountState>,
  ) => {
    state.accountInfo = payload.accountInfo;
    state.memberInfo = payload.memberInfo;
    state.actionResult = 'PLACE_CHANGE_OK';
    state.isLoading = false;
    state.error = null;
  },
  placeChangeFailure: (state: AccountState, action: PayloadAction<string>) => {
    state.actionResult = 'PLACE_CHANGE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  imageUpload: (
    state: AccountState,
    { payload }: PayloadAction<ImagePayload>,
  ) => {
    state.actionResult = 'UPLOAD_REQ';
    state.isLoading = true;
    state.error = null;
  },
  imageUploadSuccess: (
    state: AccountState,
    { payload }: PayloadAction<AccountState>,
  ) => {
    state.accountInfo = payload.accountInfo;
    state.actionResult = 'UPLOAD_OK';
    state.isLoading = false;
    state.error = null;
  },
  imageUploadFailure: (
    state: AccountState,
    { payload }: PayloadAction<String>,
  ) => {
    state.actionResult = 'UPLOAD_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  actionResultClear: (state: AuthState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'account',
  initialState: accountInitialState,
  reducers: reducers,
});

const selectAccountInfo = createDraftSafeSelector(
  (state: AccountState) => state.accountInfo,
  accountInfo => accountInfo,
);

const selectMemberInfo = createDraftSafeSelector(
  (state: AccountState) => state.memberInfo,
  memberInfo => memberInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: AccountState) => state.actionResult,
  (state: AccountState) => state.isLoading,
  (state: AccountState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const accountSelector = {
  accountInfo: state => selectAccountInfo(state[ACCOUNT]),
  memberInfo: state => selectMemberInfo(state[ACCOUNT]),
  status: state => selectStatus(state[ACCOUNT]),
};

export const ACCOUNT = slice.name;
export const accountReducer = slice.reducer;
export const accountAction = slice.actions;
