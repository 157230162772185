import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';

import { HOME, homeReducer } from './homeSlice';
import { OPEN, openReducer } from './openSlice';
import { AUTH, authReducer } from './authSlice';
import { ACCOUNT, accountReducer } from './accountSlice';
import { BOARD, boardReducer } from './boardSlice';
import { PLACE, placeReducer } from './placeSlice';
import { LAYOUT, layoutReducer } from './layoutSlice';
import { CONTENTS, contentsReducer } from './contentsSlice';
import { PUBLICCONTENTS, publicContentsReducer } from './publicContentsSlice';
import { FRAME, frameReducer } from './frameSlice';
import { LAYER, layerReducer } from './layerSlice';
import { LAYERCONTENTS, layerContentsReducer } from './layerContentsSlice';
import { LAYERHISTORY, layerHistoryReducer } from './layerHistorySlice';
import { ACTIVEITEM, activeItemReducer } from './activeItemSlice';
// import { SCHEDULE, scheduleReducer } from './scheduleSlice';
import { PLAYLIST, playlistReducer } from './playlistSlice';
import { GROUP, groupReducer } from './groupSlice';
import { DEVICE, deviceReducer } from './deviceSlice';
import { OVERLAY, overlayReducer } from './overlaySlice';
import { OVERLAYCONTENTS, overlayContentsReducer } from './overlayContentsSlice';
import { TEMPLATE, templateReducer } from './templateSlice';
import { APP, appReducer } from './appSlice';
import { CLIPBOARD, clipboardReducer } from './clipboardSlice';
import { EDITOR, editorReducer } from './editorSlice';
import { CHANNEL, channelReducer } from './channelSlice';
import { CHANNELCONTENTS, channelContentsReducer } from './channelContentsSlice';

import homeSaga from './homeSaga';
import openSaga from './openSaga';
import authSaga from './authSaga';
import accountSaga from './accountSaga';
import boardSaga from './boardSaga';
import placeSaga from './placeSaga';
import contentsSaga from './contentsSaga';
import publicContentsSaga from './publicContentsSaga';
import frameSaga from './frameSaga';
import layerSaga from './layerSaga';
import layerHistorySaga from './layerHistorySaga';
import layerContentsSaga from './layerContentsSaga';
import scheduleSaga from './scheduleSaga';
import playlistSaga from './playlistSaga';
import groupSaga from './groupSaga';
import deviceSaga from './deviceSaga';
import overlaySaga from './overlaySaga';
import overlayContentsSaga from './overlayContentsSaga';
import templateSaga from './templateSaga';
import appSaga from './appSaga';
import channelSaga from './channelSaga';
import channelContentsSaga from './channelContentsSaga';

import { interactionBaseApi } from '../rtk/interactionBaseApi';

const createRootReducer = () =>
  combineReducers({
    [HOME]: homeReducer,
    [OPEN]: openReducer,
    [AUTH]: authReducer,
    [ACCOUNT]: accountReducer,
    [BOARD]: boardReducer,
    [PLACE]: placeReducer,
    [LAYOUT]: layoutReducer,
    [FRAME]: frameReducer,
    [LAYER]: layerReducer,
    [LAYERHISTORY]: layerHistoryReducer,
    [LAYERCONTENTS]: layerContentsReducer,
    [CONTENTS]: contentsReducer,
    [PUBLICCONTENTS]: publicContentsReducer,
    [ACTIVEITEM]: activeItemReducer,
    [EDITOR]: editorReducer,
    // [SCHEDULE]: scheduleReducer,
    [PLAYLIST]: playlistReducer,
    [GROUP]: groupReducer,
    [DEVICE]: deviceReducer,
    [OVERLAY]: overlayReducer,
    [OVERLAYCONTENTS]: overlayContentsReducer,
    [TEMPLATE]: templateReducer,
    [APP]: appReducer,
    [CLIPBOARD]: clipboardReducer,
    [CHANNEL]: channelReducer,
    [CHANNELCONTENTS]: channelContentsReducer,
    [interactionBaseApi.reducerPath]: interactionBaseApi.reducer,
  });

const sagaMiddleware = createSagaMiddleware();

function* rootSaga() {
  yield all([
    homeSaga(),
    openSaga(),
    authSaga(),
    accountSaga(),
    boardSaga(),
    placeSaga(),
    contentsSaga(),
    publicContentsSaga(),
    frameSaga(),
    layerSaga(),
    layerHistorySaga(),
    layerContentsSaga(),
    scheduleSaga(),
    playlistSaga(),
    groupSaga(),
    deviceSaga(),
    overlaySaga(),
    overlayContentsSaga(),
    templateSaga(),
    channelSaga(),
    channelContentsSaga(),
    appSaga(),
  ]);
}

export default function createStore() {
  const store = configureStore({
    reducer: createRootReducer(),
    devTools: true,
    middleware: getDefaultMiddleware => [
      ...getDefaultMiddleware({ serializableCheck: false }),
      interactionBaseApi.middleware,
      sagaMiddleware,
    ],
  });

  sagaMiddleware.run(rootSaga);

  return store;
}
