import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

import { authApi } from 'rtk/authApi';

interface LoginPayload {
  alias: string;
  loginId: string;
  loginPw: string;
  idSave: string;
}

interface SignUpPayload {
  loginType: string;
  loginId: string;
  loginPw: string;
  loginNm: string;
}

interface VerifyPayload {
  verifyToken: string;
}

interface ResetPayload {
  origin: string;
  loginId: string;
}

interface ChangePwPayload {
  changeToken: string;
  loginPw: string;
}

interface UserInfo {
  userNm: string;
  lastLoginDt: string;
  accessToken: string;
}

interface AuthState {
  isLogin: boolean;
  userInfo: UserInfo;
  actionResult: string;
  isLoading: boolean;
  error: string;
}

const authInitialState: AuthState = {
  isLogin: false,
  userInfo: {},
  actionResult: '',
  isLoading: false,
  error: '',
};

const reducers = {
  login: (state: AuthState, { payload }: PayloadAction<LoginPayload>) => {
    state.isLoading = true;
    state.actionResult = 'LOGIN_REQ';
    state.error = '';
  },
  loginSuccess: (state: AuthState, { payload }: PayloadAction<AuthState>) => {
    state.isLogin = true;
    state.userInfo = payload.userInfo;
    state.isLoading = false;
    state.actionResult = 'LOGIN_OK';
    state.error = '';
  },
  loginFailure: (state: AuthState, action: PayloadAction<string>) => {
    state.isLogin = false;
    state.userInfo = {};
    state.isLoading = false;
    state.actionResult = 'LOGIN_ERR';
    state.error = action.payload;
  },
  token: (state: AuthState) => {
    state.isLoading = true;
    state.actionResult = 'TOKEN_REQ';
    state.error = '';
  },
  tokenSuccess: (state: AuthState, { payload }: PayloadAction<AuthState>) => {
    state.isLogin = true;
    state.userInfo = payload.userInfo;
    state.isLoading = false;
    state.actionResult = 'TOKEN_OK';
    state.error = '';
  },
  tokenFailure: (state: AuthState, action: PayloadAction<string>) => {
    state.isLogin = false;
    state.isLoading = false;
    state.actionResult = 'TOKEN_ERR';
    state.error = action.payload;
  },
  logout: (state: AuthState, { payload }: PayloadAction<LoginPayload>) => {
    state.isLoading = true;
    state.actionResult = 'LOGOUT_REQ';
    state.error = '';
  },
  logoutSuccess: (state: AuthState) => {
    state.isLogin = false;
    state.userInfo = {};
    state.isLoading = false;
    state.actionResult = 'LOGOUT_OK';
    state.error = '';
  },
  logoutFailure: (state: AuthState, action: PayloadAction<string>) => {
    state.isLogin = false;
    state.userInfo = {};
    state.isLoading = false;
    state.actionResult = 'LOGOUT_ERR';
    state.error = action.payload;
  },
  signUp: (state: AuthState, { payload }: PayloadAction<SignUpPayload>) => {
    state.isLoading = true;
    state.actionResult = 'SIGNUP_REQ';
    state.error = '';
  },
  signUpSuccess: (state: AuthState) => {
    state.isLoading = false;
    state.actionResult = 'SIGNUP_OK';
    state.error = '';
  },
  signUpFailure: (state: AuthState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'SIGNUP_ERR';
    state.error = action.payload;
  },
  verify: (state: AuthState, { payload }: PayloadAction<VerifyPayload>) => {
    state.isLoading = true;
    state.actionResult = 'VERIFY_REQ';
    state.error = '';
  },
  verifySuccess: (state: AuthState, { payload }: PayloadAction<AuthState>) => {
    state.isLoading = false;
    state.actionResult = 'VERIFY_OK';
    state.error = '';
  },
  verifyFailure: (state: AuthState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'VERIFY_ERR';
    state.error = action.payload;
  },
  reset: (state: AuthState, { payload }: PayloadAction<ResetPayload>) => {
    state.isLoading = true;
    state.actionResult = 'RESET_REQ';
    state.error = '';
  },
  resetSuccess: (state: AuthState, { payload }: PayloadAction<AuthState>) => {
    state.userInfo = payload.userInfo;
    state.isLoading = false;
    state.actionResult = 'RESET_OK';
    state.error = '';
  },
  resetFailure: (state: AuthState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'RESET_ERR';
    state.error = action.payload;
  },
  changePw: (state: AuthState, { payload }: PayloadAction<ChangePwPayload>) => {
    state.isLoading = true;
    state.actionResult = 'CHANGEPW_REQ';
    state.error = '';
  },
  changePwSuccess: (state: AuthState, { payload }: PayloadAction<AuthState>) => {
    state.isLoading = false;
    state.actionResult = 'CHANGEPW_OK';
    state.error = '';
  },
  changePwFailure: (state: AuthState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'CHANGEPW_ERR';
    state.error = action.payload;
  },
  actionResultClear: (state: AuthState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: reducers,
  extraReducers: builder => {
    builder.addMatcher(authApi.endpoints.createToken.matchFulfilled, (state, { payload }) => {
      state.isLogin = true;
      state.userInfo = payload;
    });
    builder.addMatcher(authApi.endpoints.refreshToken.matchFulfilled, (state, { payload }) => {
      state.isLogin = true;
      state.userInfo = payload;
    });
  },
});

const selectUserInfo = createDraftSafeSelector(
  (state: AuthState) => state.userInfo,
  userInfo => userInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: AuthState) => state.isLogin,
  (state: AuthState) => state.actionResult,
  (state: AuthState) => state.isLoading,
  (state: AuthState) => state.error,
  (isLogin, actionResult, isLoading, error) => ({
    isLogin,
    actionResult,
    isLoading,
    error,
  }),
);

export const authSelector = {
  userInfo: state => selectUserInfo(state[AUTH]),
  status: state => selectStatus(state[AUTH]),
};

export const AUTH = slice.name;
export const authReducer = slice.reducer;
export const authAction = slice.actions;
